<template>
  <el-footer class="footer">
    <el-row class="footer-top">
      <el-row type="flex" justify="center" class="footer-top-row">
        <span class="footer-top-text">即刻开启智慧管理，助力政企行业降本增效</span>
      </el-row>
      <el-row type="flex" justify="center" class="footer-top-row-button">
        <el-button round>了解详情</el-button>
      </el-row>
    </el-row>
    <el-row class="footer-bottom">
      <el-row type="flex" justify="center" :gutter="20" class="footer-bottom-about">
        <el-col :span="3">
          <img class="footer-bottom-about-logo" src="../assets/image/version1/logo-footer.png" alt="">
        </el-col>
        <el-col :span="2" class="footer-bottom-about-line">
          <div></div>
        </el-col>
        <el-col :span="2">
          <div class="footer-bottom-about-title">关于惠岑</div>
          <div class="footer-bottom-about-text">公司简介</div>
          <div class="footer-bottom-about-text">发展历程</div>
          <div class="footer-bottom-about-text">联系我们</div>
          <div class="footer-bottom-about-text">加入我们</div>
        </el-col>
        <el-col :span="10">
          <div class="footer-bottom-about-title">联系方式</div>
          <div class="footer-bottom-about-text">地址：河南省郑州市金水区经三路与农业路交叉口财富广场3号楼17层</div>
          <div class="footer-bottom-about-text">服务热线：183-3639-0408</div>
          <div class="footer-bottom-about-text">定制开发与商务合作：183-3639-0408</div>
          <div class="footer-bottom-about-text">工作时间：周一至周日，8:00am~18:00pm</div>
          <div class="footer-bottom-about-text">合作邮箱：1234567897@163.com</div>
        </el-col>
        <el-col :span="3">
          <div style="text-align: center;">
            <img class="footer-bottom-about-wx" src="../assets/image/version1/wx.png" alt="">
          </div>
          <div class="footer-bottom-about-text" style="text-align: center;">添加企业微信联系我们</div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" class="footer-bottom-line">
        <el-col :span="20"></el-col>
      </el-row>
      <el-row type="flex" justify="center" :gutter="20" class="footer-bottom-link">
        <!--- span的值不是整数，大小会失效，实际和内容一样 -->
        <el-col :span="1.9">友情链接：</el-col>
        <el-col :span="1.8"><a href="https://www.henan.gov.cn/" target="_blank" style="">河南省人民政府</a></el-col>
        <el-col :span="2.3"><a href="https://kjt.henan.gov.cn/" target="_blank" style="">河南省科学技术厅</a></el-col>
        <el-col :span="2.9"><a href="https://gxj.zhengzhou.gov.cn/" target="_blank" style="">郑州市工业和信息化局</a></el-col>
        <el-col :span="3.7"><a href="https://www.smeha.cn/" target="_blank" style="">河南省中小企业公共服务平台</a></el-col>
        <el-col :span="1.7"><a href="https://zzkj.zhengzhou.gov.cn/" target="_blank" style="">郑州市科技局</a></el-col>
        <el-col :span="1.5"><a href="http://kjt.henan.gov.cn/" target="_blank" style="">河南科技网</a></el-col>
        <el-col :span="1.2"><a href="http://zxqy.hnkjt.gov.cn" target="_blank" style="">河南省科技型中小企业评价系统</a></el-col>
      </el-row>
      <el-row type="flex" justify="center" class="footer-bottom-line">
        <el-col :span="20"></el-col>
      </el-row>
      <el-row type="flex" class="footer-bottom-version">
        <el-col style="text-align:center;">
          <span>Copyrlght © 2020-2025 河南惠岑企业管理咨询有限公司 All Rights Reserved</span>
          &nbsp;
          <a href="https://beian.miit.gov.cn" target="_blank">
            ICP编号：豫ICP备18040461号-1
          </a>
        </el-col>
      </el-row>
    </el-row>
  </el-footer>
</template>

<script>
export default {
  name: "navFooter",
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    gotoAbout() {
      this.$router.push({path: "about"});
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding: vwf(0);
  .footer-top{
    background-image: url("../assets/image/version1/footer-top.png");
    background-position: center center;
    background-size: cover;
    height: vwf(179);
    text-align: center;
  }
  .footer-top-row {
    margin-top: vwf(37);
  }
  .footer-top-row-button {
    margin-top: vwf(28);
    .el-button {
      width: vwf(132);
      height: vwf(42);
      margin: 0 auto;
      border: 0px;
      color: #006DB8;
      padding: vwf(11) vwf(36);
      font-size: vwf(14);
      font-family: Microsoft YaHei-Regular,Microsoft YaHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,SimSun,sans-serif;
      border-radius: vwf(21);
    }
  }
  .footer-top-text {
    font-size: vwf(28);
    color: #FFFFFF;
  }

  .footer-bottom {
    height: vwf(429);
    background: #212636;
  }
  .footer-bottom-about {
    margin-top: vwf(90);
    height: vwf(215);
    .footer-bottom-about-logo {
      width:vwf(132);
      height:vwf(96);
      vertical-align: middle;
    }
    .footer-bottom-about-wx {
      width:vwf(120);
      height:vwf(120);
      vertical-align: middle;
    }
    .footer-bottom-about-line {
      div {
        height: vwf(144);
        border-left: vwf(1) solid #2F3B5B;
      }
    }
    .footer-bottom-about-title {
      font-size: vwf(18);
      color:#FFFFFF;
      margin-bottom: vwf(20);
    }
    .footer-bottom-about-text {
      font-size: vwf(14);
      color: #808BA4;
      margin-top: vwf(10);
    }
  }
  .footer-bottom-link {
    height: vwf(60);
    align-items: center;
    .el-col {
      color: #E8E9EC;
      font-size: vwf(14);
    }
    a {
      color: #E8E9EC;
      font-size: vwf(14);
    }
  }
  .footer-bottom-line {
    .el-col {
      border-bottom: vwf(1) solid #2F3B5B;
    }
  }
  .footer-bottom-version {
    height: vwf(60);
    align-items: center;
    span {
      font-size: vwf(14);
      color:#8E8EA0;
    }
    a {
      font-size: vwf(14);
      color:#8E8EA0;
    }
  }
}
</style>