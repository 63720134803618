<template>
  <div id="app">
    <router-view></router-view>
    <div class="app-contact-us">
      <el-button v-popover:contactUsPopover>
        <img class="app-contact-us-img" src="./assets/image/version1/contact-us.png"/>
        <span>联系我们</span>
      </el-button>
      <el-popover ref="contactUsPopover" placement="left" trigger="hover" popper-class="contact-us-popover">
        <div class="contact-us-popover-phone">
          <img class="contact-phone-icon" src="./assets/image/version1/contact-us-phone.png"/>
          <div class="contact-txt">
            <div class="contact-txt-title">咨询电话</div>
            <div class="contact-txt-content" style="color: #006DB8;">183-3639-0408</div>
          </div>
        </div>
        <div class="contact-us-popover-message">
          <img class="contact-message-icon" src="./assets/image/version1/contact-us-message.png"/>
          <div class="contact-txt">
            <div class="contact-txt-title">在线客服</div>
            <div class="contact-txt-content">添加企业微信联系我们</div>
            <img class="contact-wx-icon" src="./assets/image/version1/wx.png"/>
          </div>
        </div>
      </el-popover>
    </div>

    <el-backtop>
      <div class="app-backtop">
        <img class="app-backtop-img" src="./assets/image/version1/backTop.png"/>
      </div>
    </el-backtop>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="scss">
@import url("assets/main.scss");

#app {
  background-color: #fff;
}

.app-contact-us {
  position: fixed;
  right: vwf(40);
  bottom: vwf(120);

  .el-button {
    text-align: center;
    align-items: center;
    display: flex;
    width: vwf(60);
    height: vwf(172);
    margin: 0 auto;
    background: #006DB8;
    border: 0px solid #DCDFE6;
    color: #FFFFFF;
    font-weight: 400;
    padding: vwf(17) vwf(18) vwf(22) vwf(18);
    font-size: vwf(18);
    font-family: Microsoft YaHei-Regular,Microsoft YaHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,SimSun,sans-serif;
    border-radius: vwf(30);
    white-space: pre-wrap;
  }
  .el-button:hover {
    color: #FFFFFF;
    background: #006DB8;
  }
  .el-button:focus {
    color: #FFFFFF;
    background: #006DB8;
  }

  .app-contact-us-img {
    width: vwf(24);
    height: vwf(24);
    margin-bottom: vwf(12);
  }
}

.contact-us-popover {
  width: vwf(374);
  height: vwf(360);
  .contact-us-popover-phone {
    display: flex;
    justify-content: flex-start;
    margin-top: vwf(20);
    margin-left: vwf(24);
  }
  .contact-us-popover-message {
    display: flex;
    justify-content: flex-start;
    margin-top: vwf(30);
    margin-left: vwf(24);
  }
  .contact-txt {
    margin-left: vwf(11);
    .contact-txt-title {
      font-size: vwf(18);
      color: #333333;
    }
    .contact-txt-content {
      font-size: vwf(14);
      color: #333333;
    }
  }
  .contact-phone-icon {
    width: vwf(45);
    height: vwf(45);
  }
  .contact-message-icon {
    width: vwf(45);
    height: vwf(45);
  }
  .contact-wx-icon {
    width: vwf(180);
    height: vwf(180);
  }
}

.app-backtop {
  text-align: center;
  align-items: center;
  display: flex;
}
.app-backtop-img {
  width: vwf(26);
  height: vwf(26);
}

// 覆盖elementUI
.el-backtop {
  width: vwf(60) !important;
  height: vwf(60) !important;
  box-shadow: vwf(0) vwf(3) vwf(6) vwf(1) rgba(0,0,0,0.16) !important;
  right: vwf(40) !important;
  bottom: vwf(40) !important;
}
</style>
