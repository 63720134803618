import Vue from 'vue'
import Router from 'vue-router'
import Home from '../pages/home'
Vue.use(Router);


//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


export default new Router({
    routes: [
        {
            path: '/',
            redirect: "/index",
        },
        {
            path: "/index",
            name: "home",
            component: Home
        },
        {
            path: "/about",
            name: "about",
            component: () => import("../pages/about")
        },
        {
            path: "/service",
            name: "service",
            component: () => import("../pages/service")
        },
        {
            path: "/allZc",
            name: "allZc",
            component: () => import("../pages/allZc")
        },
        {
            path: "/detail",
            name: "detail",
            component: () => import("../pages/detail")
        },
    ]
});

