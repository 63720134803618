import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  // 在Vue项目中用到路由，必须把路由实例对象通过下面的方式进行挂载
  // router:路由的实例对象
  router
}).$mount('#app')
